@import '../../../shared/shared';

.nav {
  position: relative;
  @include column-centered();
  width: 100%;
  max-width: toRem(400);
  padding: toRem(8) toRem(16);
  border-radius: toRem(16);
  background-color: #fff;
  display: flex;

  @media screen and (min-width: $two-xl) {
    position: absolute;
    top: toRem(239 + 64);
    left: 0;
    display: flex;
    width: toRem(239);
    height: toRem(104);
    padding: toRem(16);
    border-radius: toRem(24);
    transform: rotateZ(-90deg);
    transform-origin: top left;
    flex-shrink: 0;
  }
}

.title {
  text-align: center;
  width: 100%;
  font-family: var(--font-family);
  font-size: toRem(12);
  font-weight: bold;
  line-height: 1.17;
  color: #361c19;
  margin-bottom: toRem(8);
  opacity: 0;
  pointer-events: none;

  @media screen and (min-width: $two-xl) {
    top: toRem(16);
    font-size: toRem(20);
    line-height: 1.2;
    margin-bottom: toRem(16);
  }

  &.linked {
    position: absolute;
    top: toRem(8);
    left: 0;
    transition: opacity 0.3s ease-out;

    @media screen and (min-width: $two-xl) {
      top: 100%;
      margin-top: toRem(16);
      transform: rotateZ(180deg);
    }
  }
}

.nav-list {
  list-style: none;
  @include between-centered();
  width: 100%;

  @media screen and (min-width: $two-xl) {
    transform: rotateZ(180deg);
  }

  &:has(> :hover) {
    .nav-link:not(:hover) {
      .title {
        opacity: 0;
      }
    }
  }
}

.nav-link {
  &.active {
    .title {
      opacity: 1;
    }

    &:nth-child(1) {
      .nav-item {
        border-color: #d48502;
        background-color: #fdbe55;
      }
    }

    &:nth-child(2) {
      .nav-item {
        border-color: #f01400;
        background-color: #ff786c;
      }
    }

    &:nth-child(3) {
      .nav-item {
        border-color: #5b5299;
        background-color: #a29cca;
      }
    }

    &:nth-child(4) {
      .nav-item {
        border-color: #216360;
        background-color: #40bfb8;
      }
    }
  }

  @media (hover: hover) {
    &:hover {
      &:not(.active) {
        .title {
          opacity: 0.5;
        }

        &:nth-child(1) {
          .nav-item {
            border-color: #0000;
            background-color: rgb(253, 190, 85, 0.5);
          }
        }

        &:nth-child(2) {
          .nav-item {
            border-color: #0000;
            background-color: rgb(255, 120, 108, 0.5);
          }
        }

        &:nth-child(3) {
          .nav-item {
            border-color: #0000;
            background-color: rgb(162, 156, 202, 0.5);
          }
        }

        &:nth-child(4) {
          .nav-item {
            border-color: #0000;
            background-color: rgb(64, 191, 184, 0.5);
          }
        }
      }
    }
  }
}

.nav-item {
  width: toRem(40);
  height: toRem(40);
  border-radius: 50%;
  border: solid toRem(2) #c7c7c7;
  background-color: #c7c7c7;
  transition: all 0.3s ease-out;
  cursor: pointer;
}
