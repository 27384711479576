@import '../../shared/shared';

.about-container {
  width: 100%;
  height: 100%;
}

.landing {
  position: relative;
  width: 100%;

  padding: toRem(32 + 88) toRem(16) 0;
  margin-bottom: toRem(32);

  @media screen and (min-width: $md) {
    padding: toRem(24 + 88) toRem(48) 0;
  }
  @media screen and (min-width: $two-xl) {
    padding: toRem(24 + 136) toRem(64) 0;
  }
  @media screen and (min-width: $two-xl) and (min-height: toRem(780)) {
    height: 100%;
    margin: 0;
  }
}

.bg-img {
  position: absolute;
  top: toRem(32);
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  background: url(../../assets/images/about/about-bg.webp);
  background-size: contain;
  background-position: center toRem(200);
  background-repeat: no-repeat;
  z-index: 0;
  opacity: 0.25;

  @media screen and (min-width: $xl) {
    background-size: cover;
  }
}

.intro-container {
  position: relative;
  width: 100%;
  padding: toRem(8) 0 0;
  z-index: 1;

  @media screen and (min-width: $md) {
    padding: toRem(120) 0 0;
  }
  @media screen and (min-width: $three-xl) and (min-height: toRem(780)) {
    padding: toRem(140) 0 0;
  }
}

.horizontal-line {
  @include display-flex();
  width: toRem(264);
  height: toRem(8);
  margin-bottom: toRem(16);

  @media screen and (min-width: $two-xl) {
    width: toRem(708);
    height: toRem(16);
  }

  div {
    width: 25%;
    height: 100%;
  }

  .yellow {
    background-color: #fdbe55;
  }

  .orange {
    background-color: #ff786c;
  }

  .purple {
    background-color: #a29cca;
  }

  .green {
    background-color: #40bfb8;
  }
}

.title {
  font-family: var(--font-family);
  font-size: toRem(48);
  font-weight: 800;
  line-height: 1.21;
  color: #361c19;
  margin-bottom: toRem(16);

  @media screen and (min-width: $sm) {
    font-size: toRem(86);
    font-weight: 900;
    line-height: 1.1;
    margin-bottom: toRem(20);
  }
  @media screen and (min-width: $lg) {
    font-size: toRem(120);
    font-weight: 900;
    line-height: 1.21;
    margin-bottom: toRem(20);
  }
  @media screen and (min-width: $three-xl) and (min-height: toRem(780)) {
    font-size: toRem(200);
    font-weight: 900;
    line-height: 0.92;
    margin-bottom: toRem(24);
  }
}

.paragraph {
  position: relative;
  font-family: var(--font-family);
  font-size: toRem(16);
  font-weight: bold;
  line-height: 1.19;
  color: #6e4a02;
  z-index: 1;

  @media screen and (min-width: $md) {
    font-size: toRem(24);
    font-weight: 800;
    line-height: 1.21;
  }
  @media screen and (min-width: $xl) {
    font-size: toRem(32);
    font-weight: 800;
    line-height: 1.21;
  }
  @media screen and (min-width: $three-xl) and (min-height: toRem(780)) {
    font-size: toRem(48);
    font-weight: 800;
    line-height: 1.21;
  }

  .yellow {
    color: #dd9504;
  }
}

.main-content__container {
  width: 100%;
}

.content-container {
  position: relative;
  z-index: 1;
  padding: 0 toRem(16);

  @media screen and (min-width: $md) {
    padding: 0 toRem(48);
  }
  @media screen and (min-width: $two-xl) {
    @include display-flex();
    padding: 0 toRem(64);
  }
}

.section-container {
  width: 100%;

  @media screen and (min-width: $two-xl) {
    width: calc(100% - toRem(136));
    transition: transform 0.1s linear;
  }
}

.section {
  width: 100%;
  padding-top: toRem(78 + 88 + 24);
  padding-bottom: toRem(86);

  @media screen and (min-width: $two-xl) {
    padding: toRem(200) 0 toRem(86);
  }

  // &:first-child {
  //   padding-top: toRem(78+88 + 24);

  //   @media screen and (min-width: $two-xl) {
  //     padding: toRem(200) 0 toRem(86);
  //   }
  // }

  &:last-child {
    padding-bottom: 0;
  }
}

.aside {
  @include centered();
  align-items: flex-start;
  position: -webkit-sticky;
  position: -moz-sticky;
  position: -ms-sticky;
  position: -o-sticky;
  position: sticky;
  top: toRem(88);
  width: 100%;
  height: 0;
  z-index: 5;

  @media screen and (min-width: $two-xl) {
    top: toRem(200);
    margin-top: toRem(200);
    height: 100vh;
    align-items: flex-start;
    width: toRem(200 - 64);
    flex-shrink: 0;
  }
}

// .season-footer {
//
// }
