@import '../../shared/shared';

.footer {
  width: 100%;
  @include column-centered();
  padding-bottom: toRem(153);

  @media screen and (min-width: $two-xl) {
    padding-bottom: toRem(202);
  }
}

.title {
  color: #361c19;
  font-family: var(--font-family);
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: toRem(8);

  @media screen and (min-width: $two-xl) {
    font-size: 40px;
    font-weight: 500;
    line-height: 60px;
    margin-bottom: toRem(32);
  }
}

.horizontal-line {
  @include display-flex();
  width: toRem(267);
  height: toRem(8);
  margin-bottom: toRem(8);

  @media screen and (min-width: $two-xl) {
    width: toRem(708);
    height: toRem(16);
  }

  div {
    width: 25%;
    height: 100%;
  }

  .yellow {
    background-color: #fdbe55;
  }

  .orange {
    background-color: #ff786c;
  }

  .purple {
    background-color: #a29cca;
  }

  .green {
    background-color: #40bfb8;
  }
}
