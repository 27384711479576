@import '../../../shared/shared';

.team {
  list-style: none;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: toRem(32);

  @media screen and (min-width: $md) {
    grid-template-columns: 1fr 1fr;
  }
  @media screen and (min-width: $two-xl) {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
