@import '../../shared/shared';

.card {
  width: 100%;
  position: relative;
  background: #fcc555;
  overflow: hidden;
  margin: toRem(20) 0;

  @media screen and (min-width: $md) {
    margin: toRem(48) 0 toRem(96);
  }
  @media screen and (min-width: $three-xl) {
    margin: toRem(96) 0 toRem(150);
  }
}

.content {
  padding: toRem(24) toRem(16);

  @media screen and (min-width: $md) {
    padding: toRem(32) toRem(56);
  }
  @media screen and (min-width: $three-xl) {
    padding: toRem(72) toRem(110);
  }
}

.container {
  position: absolute;
  width: auto;
  height: 100%;
  top: toRem(16);
  right: 0;
  transform: translateX(0%);

  @media screen and (min-width: $three-xl) {
    // width: 30%;
    padding: toRem(24) toRem(16);
  }
}

.desc {
  font-family: var(--font-family);
  font-size: toRem(12);
  font-weight: 500;
  line-height: toRem(14);
  color: #6e4a02;
  margin-bottom: toRem(6);

  @media screen and (min-width: $md) {
    font-size: toRem(24);
    line-height: normal;
    margin-bottom: toRem(32);
  }
  @media screen and (min-width: $two-xl) {
    font-size: toRem(36);
  }
  @media screen and (min-width: $three-xl) {
    font-size: toRem(50);
    margin-bottom: toRem(52);
  }

  .bold {
    font-weight: 700;
  }
}

.title {
  font-family: var(--font-family);
  font-size: toRem(64);
  font-weight: 800;
  line-height: toRem(76);
  color: #361c19;
  margin-bottom: toRem(6);

  @media screen and (min-width: $md) {
    leading-trim: both;
    text-edge: cap;
    font-size: toRem(120);
    font-weight: 900;
    line-height: normal;
    margin-bottom: toRem(32);
  }
  @media screen and (min-width: $two-xl) {
    font-size: toRem(200);
    margin-bottom: toRem(32);
  }
  @media screen and (min-width: $three-xl) {
    font-size: toRem(280);
    margin-bottom: toRem(52);
  }
}

.subtitle {
  display: inline-block;
  font-family: var(--font-family);
  font-size: toRem(16);
  font-weight: 500;
  line-height: toRem(24);
  background-image: linear-gradient(
    to right,
    #fda614,
    #ff786c 34%,
    #a29cca 69%,
    #40bfb8
  );
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;

  @media screen and (min-width: $md) {
    font-size: toRem(32);
    line-height: normal;
  }
  @media screen and (min-width: $two-xl) {
    font-size: toRem(48);
  }
  @media screen and (min-width: $three-xl) {
    font-size: toRem(64);
  }
}
