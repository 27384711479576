@import '../../shared/shared';

.list-container {
  @include centered();
  width: 100%;
  padding: toRem(8) toRem(16);

  @media screen and (min-width: $two-xl) {
    padding: toRem(12) toRem(16);
  }
}

.list {
  @include centered();
  justify-content: space-between;
  gap: toRem(8);
  list-style: none;
  width: 80%;
}

.item {
  width: 90%;
}

.link {
  @include centered();
  border-radius: toRem(8);
  padding: toRem(12) toRem(8);

  @media screen and (min-width: $two-xl) {
    padding: toRem(12);
  }

  @media (hover: hover) {
    &:hover {
      &.yellow {
        background: rgba(252, 197, 85, 0.32);
        border: toRem(1) solid #fbb21d;
      }
      &.purple {
        border: toRem(1) solid #a29cca;
        background: rgba(162, 156, 202, 0.32);
      }
      &.green {
        border: toRem(1) solid #40bfb8;
        background: rgba(64, 191, 184, 0.32);
      }
      &.blue {
        border: toRem(1) solid #1da1f2;
        background: rgba(29, 161, 242, 0.32);
      }
      &.dark-blue {
        border: toRem(1) solid #002bff;
        background: rgba(0, 43, 255, 0.32);
      }
    }
  }

  .icon {
    @include display-flex();
    width: toRem(14);
    margin-right: toRem(6);

    @media screen and (min-width: $two-xl) {
      width: toRem(16);
    }
  }
}

.name {
  font-family: var(--font-family);
  font-size: toRem(12);
  font-weight: 500;
  line-height: toRem(12);
  color: #361c19;

  @media screen and (min-width: $two-xl) {
    font-family: var(--font-family);
    font-size: toRem(12);
    font-weight: 500;
    line-height: toRem(14);
    color: #361c19;
  }
}
