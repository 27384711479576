@import '../../../shared/shared';

.future-item {
  width: 100%;
  border-bottom: toRem(0.5) solid #000;
  padding: toRem(20) 0;

  @media screen and (min-width: $lg) {
    width: 50%;
    border-bottom: toRem(1) solid #216360;

    &:nth-child(odd) {
      .container {
        padding-right: toRem(24);
      }
    }
    &:nth-child(even) {
      .container {
        padding-left: toRem(24);
        border-left: toRem(1) solid #216360;
      }
    }
    &:nth-child(2) {
      padding-top: 0;
    }
    &:last-child {
      width: 100%;
      border-bottom: none;
      padding-bottom: 0;
    }
  }
  @media screen and (min-width: $two-xl) {
    padding: toRem(24) 0;
  }

  &:first-child {
    padding-top: 0;

    .pfp-container {
      padding: 0;
    }
  }
  &:nth-child(3) {
    .pfp-container {
      padding: 0;
    }
  }
  &:last-child {
    padding-bottom: 0;
    border: none;
  }
}

.container {
  width: 100%;
  height: 100%;
}

.pfp-title {
  @include display-flex();
  align-items: center;
  width: 100%;
  margin-bottom: toRem(14);

  @media screen and (min-width: $two-xl) {
    margin-bottom: toRem(16);
  }
}

.pfp-container {
  position: relative;
  width: toRem(52);
  height: toRem(52);
  background: #85dfda;
  border-radius: 50%;
  margin-right: toRem(8);
  padding: toRem(16);
  overflow: hidden;

  @media screen and (min-width: $two-xl) {
    width: toRem(64);
    height: toRem(64);
    margin-right: toRem(16);
  }

  img {
    width: 100%;
    height: auto;
    overflow: hidden;
  }

  svg:nth-child(2) {
    position: absolute;
    padding: toRem(16);
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }
}

.title {
  font-family: var(--font-family);
  font-size: toRem(18);
  font-style: normal;
  font-weight: 700;
  line-height: toRem(21);
  color: #fff;

  @media screen and (min-width: $two-xl) {
    font-size: toRem(48);
    font-weight: 800;
    line-height: toRem(58);
  }
}

.desc {
  font-family: var(--font-family);
  font-size: toRem(14);
  font-style: normal;
  font-weight: 400;
  line-height: toRem(22);
  opacity: 0.8;
  color: #fff;

  @media screen and (min-width: $two-xl) {
    font-size: toRem(24);
    font-weight: 500;
    line-height: toRem(36);
  }
}
