@import '../../../shared/shared';

.dropdown-item {
  // @include column-centered();
  width: 100%;
  border-radius: toRem(10);
  border: toRem(1) solid #a29cca;
  background: #a29cca;
  box-shadow: toRem(0) toRem(0) toRem(0) toRem(6) rgba(162, 156, 202, 0.5);
  margin-bottom: toRem(24);
  overflow: hidden;

  &:last-child {
    margin-bottom: 0;
  }

  @media screen and (min-width: $xl) {
    margin-bottom: toRem(48);
  }

  &.opened {
    .dropdown-btn {
      transform: rotateZ(0);
    }
  }
}

.heading {
  background: #a29cca;
  width: 100%;
  @include between-centered();
  padding: toRem(24) toRem(16);
  cursor: pointer;

  @media screen and (min-width: $xl) {
    padding: toRem(48) toRem(32);
  }
}

.title-subtitle__symbol {
  @include centered();
}

.title-subtitle {
  @include column-flex();
  align-items: flex-end;
  margin-right: toRem(16);

  @media screen and (min-width: $xl) {
    margin-right: toRem(48);
  }
}

.title {
  color: #361c19;
  font-family: var(-font-family);
  font-size: toRem(24);
  font-style: normal;
  font-weight: 500;
  line-height: toRem(29);
  margin-bottom: toRem(4);

  @media screen and (min-width: $xl) {
    font-size: toRem(48);
    line-height: toRem(48);
    margin-bottom: toRem(8);
  }
}

.subtitle {
  color: #5b5299;
  text-align: right;
  font-family: var(-font-family);
  font-size: toRem(12);
  font-style: normal;
  font-weight: 500;
  line-height: toRem(14);

  @media screen and (min-width: $xl) {
    font-size: toRem(24);
    line-height: toRem(36);
  }
}

.dropdown-btn {
  width: toRem(24);
  height: toRem(24);
  transform: rotateZ(180deg);
  transition: transform 0.3s ease-out;

  @media screen and (min-width: $xl) {
    width: toRem(48);
    height: toRem(48);
  }
}

.symbol {
  @include display-flex();
  width: toRem(32);

  @media screen and (min-width: $xl) {
    width: toRem(64);
  }

  img {
    width: 100%;
    height: auto;
  }
}

.content {
  display: flex;
  justify-content: start;
  align-items: flex-end;
  height: 0px;
  overflow: hidden;
  transition: all 0.3s ease-out;

  .content-child {
    width: 100%;
    flex-shrink: 0;
    padding: 0 toRem(24) toRem(24);

    @media screen and (min-width: $xl) {
      padding: 0 toRem(48) toRem(48);
    }
  }
}

.desc__bg-img__container {
  @include between-centered();
  gap: toRem(24);
  margin-bottom: toRem(16);

  @media screen and (min-width: $md) {
    align-items: flex-start;
    margin-bottom: 0;
  }
  @media screen and (min-width: $xl) {
    gap: toRem(48);
  }
}

.desc {
  width: 70%;
  color: #361c19;
  font-family: var(--font-family);
  font-size: toRem(14);
  font-style: normal;
  font-weight: 500;
  line-height: toRem(22);

  @media screen and (min-width: $xl) {
    font-family: var(--font-family);
    font-size: toRem(32);
    font-style: normal;
    font-weight: 500;
    line-height: toRem(48);
    color: #361c19;
    height: 100%;
  }
}

.bg-img {
  @include display-flex();
  width: 25%;
  flex-shrink: 0;

  img {
    width: 100%;
    height: auto;
  }
}

.action {
  width: auto;
  font-family: var(--font-family);
  font-size: toRem(14);
  font-weight: 600;
  line-height: 1.5;
  color: #361c19;
  padding: toRem(8) toRem(12);
  border-radius: toRem(6);
  border: solid toRem(2) #fbb21d;
  background-color: #fcc555;
  display: none;

  &.outer {
    display: inline-block;
    text-align: flex-start;
    // width: 100%;
  }

  @media screen and (min-width: $md) {
    display: inline-block;
    &.outer {
      display: none;
    }
  }

  @media screen and (min-width: $xl) {
    font-size: toRem(24);
    font-weight: 700;
    line-height: toRem(29);
    color: #361c19;
    padding: toRem(16) toRem(32);
  }
}
