@import '../../../shared/shared';

.grab-container {
  @include column-centered();
  border-radius: toRem(10);
  border: toRem(6) solid rgba(255, 120, 108, 0.7);
  box-shadow: 0 0 0 toRem(6) rgba(255, 120, 108, 0.5);
  background-image: linear-gradient(to right, #fb776b, #fca9a2);
  padding: toRem(16);
  border: solid toRem(1) #ff786c;

  @media screen and (min-width: $md) {
    @include between-centered();
  }
  @media screen and (min-width: $xl) {
    @include between-centered();
    padding: toRem(24) toRem(48);
  }

  @media screen and (min-width: $two-xl) {
    box-shadow: 0 0 0 toRem(12) rgba(255, 120, 108, 0.5);
    border: solid toRem(1) #f01400;
    padding: toRem(32) toRem(64);
  }
}

.content {
  width: 100%;
  margin-bottom: toRem(16);

  @media screen and (min-width: $md) {
    width: auto;
    margin: 0;
  }
}

.heading {
  font-family: var(--family-font);
  font-size: toRem(16);
  font-weight: 700;
  line-height: toRem(30);
  color: #361c19;

  @media screen and (min-width: $xl) {
    font-size: toRem(24);
    font-weight: 800;
    line-height: 1.21;
    margin-bottom: toRem(12);
  }
  @media screen and (min-width: $three-xl) {
    font-size: toRem(48);
    font-weight: 800;
    line-height: 1.21;
    margin-bottom: toRem(12);
  }

  .red {
    color: #ec483a;
  }
}

.desc {
  font-family: var(--family-font);
  font-size: toRem(10);
  font-weight: 500;
  line-height: 1.2;
  color: #372501;

  @media screen and (min-width: $xl) {
    font-size: toRem(18);
    font-weight: 500;
    line-height: 1.5;
  }
  @media screen and (min-width: $three-xl) {
    font-size: toRem(32);
    font-weight: 500;
    line-height: 1.5;
  }
}

.grab-btn {
  width: 100%;
  @include centered();
  font-size: toRem(14);
  font-weight: 600;
  line-height: 1.5;
  color: #361c19;
  padding: toRem(8) toRem(12);
  border-radius: toRem(6);
  border: solid toRem(2) #fbb21d;
  background-color: #fcc555;

  @media screen and (min-width: $md) {
    width: auto;
  }

  @media screen and (min-width: $xl) {
    font-size: toRem(24);
    font-weight: bold;
    line-height: 1.21;
    padding: toRem(16) toRem(32);
    border-radius: toRem(6);
    border: solid toRem(4) #fbb21d;
  }
}
