@import '../../shared/shared';

.header {
  @include centered();
  position: fixed;
  top: toRem(8);
  width: 100%;
  padding: toRem(12) toRem(16);
  z-index: 2;
  // background-color: #feedcb;

  @media screen and (min-width: $two-xl) {
    top: toRem(24);
    padding: toRem(16) toRem(64);
  }
}

.logo-btn__container {
  width: 100%;
  @include between-centered();
}

.logo {
  width: toRem(56);
  height: toRem(56);

  @media screen and (min-width: $two-xl) {
    width: toRem(80);
    height: toRem(80);
  }
}

.connect-btn {
  font-family: var(--font-family);
  font-size: toRem(12);
  font-weight: 600;
  line-height: 1.5;
  color: #361c19;
  padding: toRem(12);
  border-radius: toRem(6);
  background-color: #fcc555;

  @media screen and (min-width: $two-xl) {
    font-size: toRem(16);
    padding: toRem(16) toRem(32);
  }
}
