@import '../../../shared/shared';

.future-list {
  @include column-flex();
  list-style: none;
  width: 100%;
  padding: toRem(24);
  border-radius: toRem(12);
  border: toRem(1) solid #216360;
  background: #40bcb5;
  box-shadow: toRem(0) toRem(0) toRem(0) toRem(6) rgba(64, 191, 184, 0.5);

  @media screen and (min-width: $lg) {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  @media screen and (min-width: $two-xl) {
    padding: toRem(50);
    border-radius: toRem(24);
    border: toRem(1) solid #216360;
    background: #40bcb5;
    box-shadow: toRem(0) toRem(0) toRem(0) toRem(12) rgba(64, 191, 184, 0.5);
  }
}
