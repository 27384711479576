@import '../../shared/shared';

.main-container {
  width: 100%;
  height: 100%;
  padding: toRem(88) 0 0;

  @media screen and (min-width: $two-xl) {
    padding: toRem(136) 0 0;
  }
}

.container {
  @include centered();
  position: relative;
  width: 100%;
  height: 100%;
}

.bg-img {
  position: absolute;
  width: 100%;
  height: 100%;
  background: url(../../assets/images/home/home-bg.webp);
  background-size: toRem(1650);
  background-position: 47% top;
  background-repeat: no-repeat;
  z-index: 1;

  @media screen and (min-width: $md) {
    background-size: cover;
    background-position: center -100%;
  }
  // @media screen and (min-width: $two-xl) {
  //   background-size: 120%;
  //   background-position: left -65%;
  // }
  @media screen and (min-width: $three-xl) {
    background-size: 110%;
    background-position: left -10%;
  }
}

.into-container {
  @include column-centered();
  position: absolute;
  top: 0;

  @media screen and (min-height: toRem(550)) {
    top: toRem(96);
  }

  @media screen and (min-width: $md) and (min-height: toRem(550)) {
    top: toRem(64);
  }
}

.horizontal-line {
  @include display-flex();
  width: toRem(264);
  height: toRem(8);
  margin-bottom: toRem(8);

  @media screen and (min-width: $two-xl) {
    width: toRem(708);
    height: toRem(16);
  }

  div {
    width: 25%;
    height: 100%;
  }

  .yellow {
    background-color: #fdbe55;
  }

  .orange {
    background-color: #ff786c;
  }

  .purple {
    background-color: #a29cca;
  }

  .green {
    background-color: #40bfb8;
  }
}

.title,
.title-special {
  font-family: var(--font-family);
  font-size: toRem(32);
  font-weight: 900;
  color: #361c19;
  text-align: center;

  @media screen and (min-width: $two-xl) {
    font-size: toRem(96);
  }
}

.title-special {
  color: #fbb21d;
  // text-shadow: toRem(1) toRem(1) #dd9504, toRem(-1) toRem(1) #dd9504,
  //   toRem(1) toRem(-1) #dd9504, toRem(-1) toRem(-1) #dd9504;

  @media screen and (min-width: $two-xl) {
    font-size: toRem(96);
    // text-shadow: toRem(4) toRem(4) #dd9504, toRem(-4) toRem(4) #dd9504,
    //   toRem(4) toRem(-4) #dd9504, toRem(-4) toRem(-4) #dd9504;
  }
}

.explore-btn {
  position: absolute;
  bottom: toRem(100);
  padding: toRem(12) toRem(24);
  border-radius: toRem(6);
  border: solid toRem(4) #fbb21d;
  background-color: #fcc555;
  z-index: 1;

  a {
    font-size: toRem(16);
    font-weight: 600;
    line-height: 1.5;
    color: #361c19;

    @media screen and (min-width: $two-xl) and (min-height: toRem(550)) {
      font-size: toRem(24);
      font-weight: bold;
      line-height: 1.21;
      padding: toRem(16) toRem(32);
    }
  }

  @media screen and (min-height: toRem(550)) {
    bottom: toRem(172);
  }
}
