@import '../../../shared/shared';

.member {
  padding: toRem(20);
  border-radius: toRem(12);
  border: solid toRem(2) #fcc555;
  background-color: #fff;

  @media screen and (min-width: $two-xl) {
    padding: toRem(24);
  }
}

.img-container {
  @include centered();
  position: relative;
  width: 100%;
  height: auto;
  margin-bottom: toRem(40);

  img {
    width: 100%;
    height: auto;
    border-radius: toRem(10);
  }
}

.twitter-link {
  @include centered();
  width: toRem(56);
  height: toRem(36);
  position: absolute;
  bottom: 0;
  transform: translateY(50%);
  border-radius: toRem(43);
  background: #fcc555;

  @media screen and (min-width: $two-xl) {
    width: toRem(60);
    height: toRem(46);
  }
}

.twitter-logo__container {
  width: toRem(20);
  height: toRem(20);

  img {
    width: 100%;
    height: auto;
  }
}

.name {
  font-family: var(--font-family);
  font-size: toRem(18);
  font-weight: 700;
  line-height: toRem(21);
  color: #361c19;
  text-align: center;

  @media screen and (min-width: $two-xl) {
    font-size: toRem(24);
    font-weight: bold;
    line-height: 1.21;
    margin-bottom: toRem(8);
  }
}

.position {
  font-family: var(--font-family);
  font-size: toRem(14);
  font-weight: 500;
  line-height: toRem(22);
  color: #565548;
  text-align: center;

  @media screen and (min-width: $two-xl) {
    font-size: toRem(16);
    line-height: 1.5;
  }
}
