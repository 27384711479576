@import 'shared/shared';

@font-face {
  font-family: 'Lexend';
  src: url('./assets/fonts/Lexend/static/Lexend-Thin.ttf') format('truetype');
  font-weight: 100;
  font-display: block;
  font-style: normal;
}

@font-face {
  font-family: 'Lexend';
  src: url('./assets/fonts/Lexend/static/Lexend-ExtraLight.ttf')
    format('truetype');
  font-weight: 200;
  font-display: block;
  font-style: normal;
}

@font-face {
  font-family: 'Lexend';
  src: url('./assets/fonts/Lexend/static/Lexend-Light.ttf') format('truetype');
  font-weight: 300;
  font-display: block;
  font-style: normal;
}

@font-face {
  font-family: 'Lexend';
  src: url('./assets/fonts/Lexend/static/Lexend-Regular.ttf') format('truetype');
  font-weight: 400;
  font-display: block;
  font-style: normal;
}

@font-face {
  font-family: 'Lexend';
  src: url('./assets/fonts/Lexend/static/Lexend-Medium.ttf') format('truetype');
  font-weight: 500;
  font-display: block;
  font-style: normal;
}

@font-face {
  font-family: 'Lexend';
  src: url('./assets/fonts/Lexend/static/Lexend-SemiBold.ttf')
    format('truetype');
  font-weight: 600;
  font-display: block;
  font-style: normal;
}

@font-face {
  font-family: 'Lexend';
  src: url('./assets/fonts/Lexend/static/Lexend-Bold.ttf') format('truetype');
  font-weight: 800;
  font-display: block;
  font-style: normal;
}

@font-face {
  font-family: 'Lexend';
  src: url('./assets/fonts/Lexend/static/Lexend-Black.ttf') format('truetype');
  font-weight: 900;
  font-display: block;
  font-style: normal;
}

@font-face {
  font-family: 'Lexend';
  src: url('./assets/fonts/Lexend/Lexend-VariableFont_wght.ttf')
    format('truetype-variations');
  font-weight: 100 900;
  font-display: block;
  font-style: normal;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;

  &:focus {
    outline: none;
  }
}

:root {
  --font-family: 'Lexend', sans-serif;
}

html,
body {
  width: 100%;
  height: 100%;
}

html {
  overflow-y: auto;
  @include custom-scrollbar();
}

body {
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: 500;
  color: #565548;
  background: #feedcb;
  min-width: toRem(320);
}

.noscroll {
  overflow: hidden;
}

#root {
  width: 100%;
  height: 100%;
}

main {
  width: 100%;
  height: 100%;
}

a {
  text-decoration: none;
  cursor: pointer;
}

button {
  background-color: #0000;
  cursor: pointer;
  border: none;
}

.skeleton {
  position: relative;
  overflow: hidden;

  &::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: var(--gray-40);
    animation: skeleton-loading 1s linear infinite alternate;
  }

  &.index {
    &::after {
      z-index: 2;
    }
  }
}

@keyframes skeleton-loading {
  0% {
    background-color: var(--glow-up);
  }
  100% {
    background-color: var(--glow-down);
  }
}
