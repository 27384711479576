@import '../../../shared/shared';

.img-container {
  position: relative;
  width: 90%;
  max-width: toRem(358);
  height: (0.8 * calc((390 - 32) / 390)) * 100vw;
  border-radius: toRem(10);
  border: toRem(6) solid rgba(255, 120, 108, 0.8);
  background: #fff;
  overflow: hidden;
  scroll-snap-align: start;
  flex-shrink: 0;

  @supports (aspect-ratio: 1/1) {
    height: auto;
    aspect-ratio: 1/1;
  }

  @media screen and (min-width: $md) {
    width: 50%;
    height: (0.5 * calc((768 - 48) / 768)) * 100vw;

    @supports (aspect-ratio: 1/1) {
      height: auto;
      aspect-ratio: 1/1;
    }
  }

  @media screen and (min-width: $xl) {
    width: 100%;
    height: (calc(((1440 / 3) - 64 - 48) / 1440)) * 100vw;
    max-width: toRem(450);

    @supports (aspect-ratio: 1/1) {
      height: auto;
      aspect-ratio: 1/1;
    }
  }

  img {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    object-fit: cover;
    object-position: center;
  }
}

.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(
    to bottom,
    rgba(14, 15, 27, 0) 0%,
    #372501 100%
  );
}

.content {
  position: absolute;
  left: toRem(24);
  bottom: toRem(32);
}

.name {
  font-family: var(--font-family);
  font-size: toRem(24);
  font-weight: 500;
  line-height: toRem(29);
  color: #fff;
  margin-bottom: toRem(4);
}

.desc {
  font-family: var(--font-family);
  font-size: toRem(16);
  font-weight: 500;
  line-height: toRem(19);
  color: #c7c7c7;
  margin-bottom: toRem(4);
}

.icon-caterory__container {
  @include centered();
  justify-content: start;
  gap: toRem(8);
  margin-bottom: toRem(4);
}

.icon {
  @include centered();
  @include centered();
  width: toRem(16);
  height: toRem(16);
}

.category-name {
  font-family: var(--font-family);
  font-size: toRem(14);
  font-weight: 400;
  line-height: toRem(22);
  color: #c7c7c7;
}

.category {
  font-size: toRem(16);
  font-weight: 500;
  line-height: 1.5;
  color: #ff786c;
  margin-bottom: toRem(4);
}

.explore-link {
  color: #372501;
}

.explore-btn {
  padding: toRem(8) toRem(16);
  border-radius: toRem(8);
  border: solid toRem(2) #fbb21d;
  background-color: #fcc555;
  color: #372501;
}
