@import '../../../shared/shared';

.horizontal-line {
  width: toRem(171);
  height: toRem(8);
  background-color: #ff786c;

  @media screen and (min-width: $two-xl) {
    display: none;
  }
}

.img-container {
  position: relative;
  width: 100%;
  height: calc(27700 / (390)) * 1vw;
  background: #ff786c;
  margin: toRem(16) 0 toRem(32);
  border-radius: toRem(8);
  overflow: hidden;
  max-height: toRem(750);

  @supports (aspect-ratio: 358/277) {
    aspect-ratio: 358/277;
    height: auto;
  }

  @media screen and (min-width: $lg) {
    height: calc(86600 / (1728)) * 1vw;

    @supports (aspect-ratio: 1465/866) {
      aspect-ratio: 1465/866;
      height: auto;
    }
  }
  @media screen and (min-width: $two-xl) {
    margin: 0 0 toRem(32);
  }

  img {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    object-fit: cover;
    object-position: 80% center;

    @media screen and (min-width: $lg) {
      object-fit: contain;
      transform: scale(1.2);
    }
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(
      180deg,
      rgba(14, 15, 27, 0) 0%,
      rgba(55, 37, 1, 0.8) 100%
    );
  }

  .title {
    position: absolute;
    left: toRem(16);
    bottom: toRem(24);
    font-size: toRem(24);
    font-weight: bold;
    line-height: 1.21;
    color: #ff786c;
    z-index: 1;

    @media screen and (min-width: $md) {
      font-size: toRem(32);
      font-weight: 600;
      line-height: 1.19;
      left: toRem(48);
      bottom: toRem(56);
    }

    @media screen and (min-width: $xl) {
      font-size: toRem(64);
      font-weight: 800;
      line-height: 1.19;
      left: toRem(64);
      bottom: toRem(96);
    }
  }
}

.paragraph {
  font-family: var(--font-family);
  font-size: toRem(14);
  font-weight: 500;
  line-height: 1.57;
  color: #565548;
  margin: toRem(32) 0;

  @media screen and (min-width: $lg) {
    font-size: toRem(24);
    line-height: 1.5;
  }

  @media screen and (min-width: $two-xl) {
    margin: toRem(64) 0;
  }

  .red {
    color: #ff786c;
  }

  .emphasis {
    color: #361c19;
  }
}
