@import '../../../shared/shared';

.container {
  width: 100%;
  padding: toRem(24) toRem(32);
  border-radius: toRem(10);
  box-shadow: 0 0 0 toRem(6) rgba(253, 190, 85, 0.5);
  border: solid toRem(1) #dd9504;
  background-color: #fdbe55;

  @media screen and (min-width: $two-xl) {
    @include between-centered();
    gap: toRem(99);
    padding: toRem(71) toRem(48);
    border-radius: toRem(24);
  }
}

.img-tile__Container {
  @media screen and (min-width: $three-xl) {
    width: 40%;
  }
}

.title {
  font-family: var(--font-family);
  font-size: toRem(14);
  font-style: normal;
  font-weight: 700;
  line-height: toRem(22);
  color: #a06c03;

  @media screen and (min-width: $two-xl) {
    font-size: toRem(32);
    font-weight: 800;
    line-height: 1.19;
  }

  .special {
    display: inline-block;
    height: toRem(12);

    @media screen and (min-width: $two-xl) {
      height: toRem(24);
    }
  }
}

.img-container {
  @include display-flex();
  width: 100%;
  max-width: toRem(294);
  margin: toRem(8) 0 toRem(16);

  @media screen and (min-width: $two-xl) {
    max-width: toRem(512);
    margin: toRem(24) 0 0;
  }
  @media screen and (min-width: $three-xl) {
  }

  img {
    width: 100%;
    height: auto;
  }
}

.value {
  font-family: var(--font-family);
  font-size: toRem(48);
  font-weight: 800;
  line-height: toRem(58);
  color: #361c19;
  margin: toRem(8) 0 toRem(16);

  @media screen and (min-width: $two-xl) {
    font-size: toRem(96);
    line-height: normal;
  }
}

.medium {
  font-size: toRem(64);
  line-height: toRem(76);

  @media screen and (min-width: $two-xl) {
    font-size: toRem(135);
    line-height: normal;
  }
}

.large {
  font-size: toRem(70);
  line-height: toRem(70);
  margin-bottom: 0;

  @media screen and (min-width: $sm) {
    font-size: toRem(80);
    line-height: normal;
  }
  @media screen and (min-width: $two-xl) {
    font-size: toRem(160);
  }
  @media screen and (min-width: $three-xl) {
    font-size: toRem(240);
  }
}
