@import '../../../shared/shared';

.article-container {
  @include display-flex();
  width: 100%;
  scroll-snap-type: x mandatory;
  overflow: auto;
  margin-bottom: toRem(32);

  @media screen and (min-width: $two-xl) {
    margin-bottom: toRem(86);
  }

  @media (pointer: fine) and (hover: hover) {
    scrollbar-color: #0000 #0000;
    scrollbar-width: thin;
    // scrollbar-gutter: stable;

    &::-webkit-scrollbar {
      width: toRem(0); /* width of the entire scrollbar */
      height: toRem(0); /* width of the entire scrollbar */
    }
    &::-webkit-scrollbar-track {
      background: transparent; /* color of the tracking area */
    }
    &::-webkit-scrollbar-thumb {
      background: #0000;
      border-radius: 0; /* creates padding around scroll thumb */

      &:hover {
        background: #0000;
      }
    }
  }
}

.article-list {
  list-style: none;
  width: 100%;
  @include display-flex();
  gap: toRem(24);
}
