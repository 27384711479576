@import '../../shared/shared';

.nav {
  @include column-centered();
  position: fixed;
  bottom: toRem(24);
  background-color: #ffffff;
  border-radius: toRem(24);
  box-shadow: 0 toRem(2) toRem(16) 0 rgba(0, 0, 0, 0.12);
  border-radius: toRem(24);
  overflow: hidden;
  width: toRem(317);

  @media screen and (min-height: toRem(550)) {
    bottom: toRem(64);
  }
  @media screen and (min-width: $two-xl) {
    width: toRem(443);
    flex-direction: column-reverse;
    top: toRem(45);
    bottom: auto;
  }
}

.nav-list {
  position: relative;
  list-style: none;
  @include centered();
  gap: toRem(8);
  background-color: #ffffff;
  padding: toRem(8) toRem(16);

  @media screen and (min-width: $two-xl) {
    padding: toRem(12) toRem(16);
  }
}

.nav-item {
  z-index: 1;
  font-family: var(--font-family);
  font-size: toRem(10);
  font-weight: 500;
  line-height: 1.2;
  padding: toRem(8) toRem(12);
  border-radius: toRem(16);
  color: #361c19;
  transition-property: color, background-color;
  transition-duration: 0.3s;
  transition-timing-function: ease-out;
  cursor: pointer;

  @media (hover: hover) {
    &:hover {
      color: rgba(54, 28, 25, 0.5);
      background-color: rgba(169, 105, 71, 0.3);
    }
  }

  @media screen and (min-width: $two-xl) {
    font-size: toRem(16);
    font-weight: bold;
    line-height: 1.88;
    border-radius: toRem(24);
    padding: toRem(8) toRem(16);
  }

  &.active {
    color: #ffffff;
  }
}

.active-wrapper {
  position: absolute;
  z-index: 0;
  left: toRem(16);
  width: toRem(54);
  height: toRem(28);
  border-radius: toRem(16);
  background-color: #a96a47;
  transition: all 0.3s ease-out;

  @media screen and (min-width: $two-xl) {
    border-radius: toRem(24);
  }
}

.drop-list {
  @include display-flex();
  align-items: flex-start;
  width: 100%;
  list-style: none;
  position: relative;
  left: 0;
  height: 0;
  transition: all 0.3s ease-out;

  @media screen and (min-width: $two-xl) {
    align-items: flex-end;
  }

  &.opened {
    height: toRem(52);

    @media screen and (min-width: $two-xl) {
      height: toRem(64);
    }
  }
}

.drop-item {
  @include centered();
  width: 100%;
  flex-shrink: 0;
}
